/**
 * @name 이벤트목록 탭
 * @param ING 진행중
 * @param END 종료
 */
export enum E_EVENT_TAB {
  'ING' = 'ing',
  'END' = 'end',
}

/**
 * @name 알림목록 탭
 * @param ALARM 알림
 * @param NOTICE 공지사항
 */
export enum E_ALARM_TAB {
  'ALARM' = 'alarm',
  'NOTICE' = 'notice',
}

/**
 * @name 상품추천 탭
 * @param MDPICK MD픽
 * @param NEW 신상품
 * @param BEST 인기상품
 * @param LIKE 관심상품
 */
export enum E_RECOMMENDPRODUCT_TAB {
  'MDPICK' = 'mdpick',
  'NEW' = 'new',
  'BEST' = 'best',
  'LIKE' = 'like',
}

/**
 * @name MY혜택 탭
 * @param COUPON 나만의 쿠폰
 * @param DISCOUNT 회원할인
 */
export enum E_MYBENEFIT_TAB {
  'COUPON' = 'coupon',
  'DISCOUNT' = 'discount',
}

/**
 * @name 로그인 결과 상태 값
 * @param NONE 자동로그인 미설정
 * @param SUCCESS 로그인 성공
 * @param FAIL 로그인 실패
 * @param INIT 로그인 처리 전 or 로그아웃
 */
export enum E_INTRO_LOGIN_STATE {
  'NONE' = 'NONE',
  'SUCCESS' = 'SUCCESS',
  'FAIL' = 'FAIL',
  'INIT' = '',
}

/**
 * @name 팝업 열기/닫기 상태값
 * @param NONE 없음
 * @param OPEN 열기
 * @param CLOSE 닫기
 */
export enum E_POPUP_STATE {
  'OPEN' = 'OPEN',
  'CLOSE' = 'CLOSE',
  'NONE' = '',
}

/**
 * @name 스노우플랜 탭
 * @param SAVE 적립
 * @param USE 사용/소멸
 */
export enum E_SNOWPLAN_TAB {
  'SAVE' = 'save',
  'USE' = 'use',
  'EXPIRE' = 'expire',
}

/**
 * @name 스노우플랜 필터
 * @param MONTH 1개월
 * @param THREEMONTH 3개월
 * @param SETDATE 기간설정
 */
export enum E_SNOWPLAN_CHIP {
  'MONTH' = 'month',
  'THREEMONTH' = 'threemonth',
  'SETDATE' = 'setdate',
  'TEST2' = 'test2',
  'TEST3' = 'test3',
  'TEST4' = 'test4',
  'TEST5' = 'test5',
  'TEST6' = 'test6',
  'TEST7' = 'test7',
  'TEST8' = 'test8',
}

export enum E_TENANT_CHIP {
  'ALL' = 'all',
  'LIFE' = 'life',
  'BEAUTY' = 'beauty',
  'KIDS' = 'kids',
  'FASHION' = 'fashion',
}

/**
 * @name 추천상품 뷰 형식
 * @param LIST 리스트 형식
 * @param GRID 그리드 형식
 */
export enum E_RECOMMENDPRODUCT_VIEW {
  'LIST' = 'list',
  'GRID' = 'grid',
}

/**
 * @name API 헤더 접근 서비스 ID
 * @param USER 회원&공통
 * @param COUPON 쿠폰
 * @param EVENT 이벤트
 * @param CLUB 클럽&입점사
 * @param PUSH 푸쉬
 * @param SCANGO 스캔앤고
 * @param PRODUCT 상품
 * @param BOTTLE 보틀벙커
 */
export enum E_API_SERVICEID {
  'USER' = 'USER',
  'COUPON' = 'COUPON',
  'EVENT' = 'EVENT',
  'ASSISTANT' = 'ASSISTANT',
  'PUSH' = 'PUSH',
  'SCANGO' = 'SCANGO',
  'PRODUCT' = 'PRODUCT',
  'BOTTLE' = 'BOTTLE',
}

/**
 * @name 회원약관 구분 값
 * @param MEMBER 롯데마트GO 회원약관
 * @param PRIVACY 롯데마트GO 개인정보 수집이용 동의
 * @param MARKETING 마케팅 목적 정보 이용 동의
 * @param PUSH 광고성 정보(PUSH) 수신 동의
 * @param CI 개인정보(CI) 수집 및 이용 동의
 * @param RECEIPT 스마트영수증만 받기 동의
 * @param CRM CRM 약관
 * @param LPOINT_PRIVACY 엘포인트 개인정보 수집이용동의
 * @param LPOINT_PROVIDE 엘포인트 제3자 제공동의
 */
export enum E_TERMS_AGMTTYPE {
  'MEMBER' = '1',
  'PRIVACY' = '2',
  'PRIVACY2' = '19',
  'MARKETING' = '3',
  'PUSH' = '4',
  'CI' = '5',
  'RECEIPT' = '6',
  'CRM' = '7',
  'LPOINT_PRIVACY' = '8',
  'LPOINT_PROVIDE' = '9',
}

/**  이벤트 참여하기
 * @name API
 * @param EVENT
 */
export enum E_PROC_SUBMIT {
  'COMPLETE' = '1',
  'DONE' = '2',
  'ERROR' = '3',
  'NODATE' = '4',
  'FULL' = '5',
  'FINISH' = '13', //기완료
}

/** 이벤트 자동,수동
 * @name API
 * @param USER
 */
export enum E_PROC_JOIN {
  'AUTO' = '1',
  'SELF' = '2',
}

/** 이벤트 참여상태
 * @name API
 * @param USER
 */
export enum E_PROC_ATTEND {
  'IN' = 'Y',
  'OUT' = 'N',
}

/**
 * @name API
 * @param USER
 */
export enum E_EVENT_DATE {
  'IN' = 'Y',
  'OUT' = 'N',
}

/** 보상 당첨여부
 * @name API
 * @param USER
 */
export enum E_AWARD_CHECK {
  'WIN' = 'Y',
  'LOSE' = 'N',
}

/**  쿠폰 다운로드 여부
 * @name API
 * @param USER
 */
export enum E_DOWN_CHECK {
  'YES' = 'Y',
  'NO' = 'N',
}

/**  랜딩 이벤트 이미지 종류
 * @name API
 * @param USER
 */
export enum E_IMAGE_KIND {
  'IMAGE' = '1',
  'BUTTON' = '2',
}

/**  링크방식 구분
 * @name API
 * @param INSIDE 앱 내 링크
 * @param OUTSIDE 외부 링크
 * @param NONE 링크없음
 */
export enum E_LINK_KIND {
  'INSIDE' = '1',
  'OUTSIDE' = '2',
  'NONE' = '3',
}

/**  스탬프 이벤트 방식 구분
 * @name API
 * @param PRICE 상품구매금액
 * @param PRODUCT 상품구매수량
 * @param ATTEND 출석체크
 * @param CAT_PRICE 카테고리금액
 * @param CAT_PRODUCT 카테고리수량
 * @param HISTORY 거래이력
 */
export enum E_STAMP_KIND {
  'PRICE' = '1',
  'PRODUCT' = '2',
  'ATTEND' = '3',
  'CAT_PRICE' = '4',
  'CAT_PRODUCT' = '5',
  'HISTORY' = '6',
}

/** 보상 종류
 * @name API
 * @param USER
 */
export enum E_AWARD_KIND {
  'YES' = 'Y',
  'NO' = 'N',
}

/** 출석체크 이벤트 당일 참여여부
 * @name API
 * @param USER
 */
export enum E_TODAY_ATTEND {
  'IN' = 'Y',
  'OUT' = 'N',
}

/** 설문 이벤트 질문유형
 * @name API
 * @param USER
 */
export enum E_QUEST_KIND {
  'CHOICE' = '1',
  'RANK' = '2',
  'TEXT' = '3',
}

/** 설문 이벤트 답변유형
 * @name API
 * @param USER
 */
export enum E_ANSWER_KIND {
  'TEXT' = '1',
  'TEXTIMAGE' = '2',
  'IMAGE' = '3',
}

/** 배너리스트 이벤트 진행상황
 * @name API
 * @param USER
 */
export enum E_PROC_BANNER {
  'READY' = '1',
  'ING' = '2',
  'CLOSE' = '3',
}

/** 배너리스트 이벤트 타입
 * @name API
 * @param USER
 */
export enum E_BANNER_KIND {
  'LANDING' = '2',
  'STAMP' = '3',
  'ACCRUE' = '4',
  'COMPETE' = '5',
  'COUPON' = '6',
  'APPLY' = '7',
  'POLL' = '8',
}

/** 배너 이벤트 참여여부
 * @name API
 * @param USER
 */
export enum E_BANNER_ATTEND {
  'NO' = '0',
  'YES' = '1',
  'DONE' = '2',
}

/** 배너 이벤트 참여여부
 * @name API
 * @param USER
 */
export enum E_COUPON_KIND {
  'PRODUCT' = '1',
  'PRICE' = '2',
  'SWAP' = '3',
  'MD' = '4',
}

/**
 * @name 포인트 혜택 이용하기 값
 */
export enum E_POINT_BENEFIT_TAB {
  'CENTER' = 'center',
  'SUPER' = 'super',
  'MOVIE' = 'movie',
  'FLY' = 'fly',
}

/**
 * @name 시스템팝업 구분 값
 * @description 1:시스템점검 2:필수업데이트
 */
export enum E_SYSTEM_POPUP_TYPE {
  'INSPECTION' = '1',
  'UPDATE' = '2',
}

/**
 * @name 풀 팝업 종류 구분값
 */
export enum E_FULL_POPUP {
  'MART_GRADE_UP' = '8',
  'SUPER_GRADE_UP' = '10',
  'NEW_USER' = '11',
}
