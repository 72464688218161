/**
 * @name 쿼리 키
 * @description [keyName,params1,params2...]
 * @param keyName 서비스ID_목록or상세_타입_구분1_구분2...
 * @param paramsN api params 고정 값 사용하는 경우 작성
 */
export const E_QUERY_KEY = {
  ASSISTANT_DETAIL_EMPINFO: ['ASSISTANT_DETAIL_EMPINFO'],
  COUPON_LIST: ['COUPON_LIST'],
  COUPON_DETAIL: ['COUPON_DETAIL'],
  CLUB_LIST_RECEIPT: ['CLUB_LIST_RECEIPT'],
  CLUB_DETAIL_RECEIPT_OFF: ['CLUB_DETAIL_RECEIPT_OFF'],
  CLUB_DETAIL_RECEIPT_ON: ['CLUB_DETAIL_RECEIPT_ON'],
  CLUB_DETAIL_RECEIPT_SCANGO: ['CLUB_DETAIL_RECEIPT_SCANGO'],
  CLUB_DETAIL_RECEIPT_SCANGO_CANCEL: ['CLUB_DETAIL_RECEIPT_SCANGO_CANCEL'],
  EVENT_LIST: ['EVENT_LIST'],
  EVENT_LIST_BANNER_MAIN: ['EVENT_LIST_BANNER_MAIN', '1'],
  EVENT_LIST_BANNER_LEAFLET: ['EVENT_LIST_BANNER_LEAFLET', '2'],
  EVENT_LIST_BANNER_SCANGO: ['EVENT_LIST_BANNER_SCANGO', '3'],
  EVENT_LIST_BANNER_RECOMMEDPRODUCT: ['EVENT_LIST_BANNER_RECOMMEDPRODUCT', '5'],
  EVENT_LIST_POPUP_MAIN: ['EVENT_LIST_POPUP_MAIN'],
  EVENT_DETAIL_COUPON: ['EVENT_DETAIL_COUPON'],
  EVENT_DETAIL_STAMP: ['EVENT_DETAIL_STAMP'],
  EVENT_DETAIL_SUPERSTAMP_ID: ['EVENT_DETAIL_SUPERSTAMP_ID'],
  EVENT_DETAIL_SUPERSTAMP_DETAIL: ['EVENT_DETAIL_SUPERSTAMP_DETAIL'],
  EVENT_DETAIL_POLL: ['EVENT_DETAIL_POLL'],
  EVENT_DETAIL_POLL_QUEST: ['EVENT_DETAIL_POLL_QUEST'],
  EVENT_DETAIL_LANDING: ['EVENT_DETAIL_LANDING'],
  EVENT_DETAIL_HIDDEN: ['EVENT_DETAIL_HIDDEN'],
  EVENT_DETAIL_COMPETE: ['EVENT_DETAIL_COMPETE'],
  EVENT_DETAIL_APPLY: ['EVENT_DETAIL_APPLY'],
  EVENT_DETAIL_APPLY_WIN: ['EVENT_DETAIL_APPLY_WIN'],
  EVENT_DETAIL_ACCRUE: ['EVENT_DETAIL_ACCRUE'],
  EVENT_DETAIL_ROULETTE_ID: ['EVENT_DETAIL_ROULETTE_ID'],
  EVENT_DETAIL_ROULETTE_DETAIL: ['EVENT_DETAIL_ROULETTE_DETAIL'],
  EVENT_DETAIL_FOUNDING_WINNER: ['EVENT_DETAIL_FOUNDING_WINNER'],
  EVENT_DETAIL_SPLASH: ['EVENT_DETAIL_SPLASH'],
  EVENT_DETAIL_BLANCE_DETAIL: ['EVENT_DETAIL_BLANCE_DETAIL'],
  EVENT_DETAIL_RNGCOUPON: ['EVENT_DETAIL_RNGCOUPON'],
  EVENT_LIST_APLLY_LIST: ['EVENT_LIST_APLLY_LIST'],
  SNOWPLAN_DETAIL_INFO: ['SNOWPLAN_DETAIL_INFO'],
  SNOWPLAN_DETAIL_BALAMCE: ['SNOWPLAN_DETAIL_BALAMCE'],
  SNOWPLAN_DETAIL_TARGETGRADE: ['SNOWPLAN_DETAIL_TARGETGRADE'],
  SNOWPLAN_LIST_TRN: ['SNOWPLAN_LIST_TRN'],
  SNOWPLAN_DETAIL_RECEIPT: ['SNOWPLAN_DETAIL_RECEIPT'],
  USER_LIST_AGMT_YN: ['USER_LIST_AGMT_YN'],
  USER_DETAIL_RECIPT_CNT: ['USER_DETAIL_RECIPT_CNT'],
  USER_DETAIL_LPOINT: ['USER_DETAIL_LPOINT'],
  USER_DETAIL_CURRENTTIME: ['USER_DETAIL_CURRENTTIME'],
  PRODUCT_LIST_RECOM_LIST: ['PRODUCT_LIST_RECOM_LIST'],
  PRODUCT_LIST_RECOM_WRAP: ['PRODUCT_LIST_RECOM_WRAP'],
  PRODUCT_LIST_LEAFLET_LIST: ['PRODUCT_LIST_LEAFLET_LIST'],
  ASSISTANT_LIST_MCLUB_CONTENT: ['ASSISTANT_LIST_MCLUB_CONTENT'],
  ASSISTANT_DETAIL_HOLDDAY_CHECK: ['ASSISTANT_DETAIL_HOLDDAY_CHECK'],
  ASSISTANT_LIST_FULLPOPUP_NEWUSER: ['ASSISTANT_LIST_FULLPOPUP_NEWUSER'],
  ASSISTANT_LIST_FULLPOPUP: ['ASSISTANT_LIST_FULLPOPUP'],
};
